import React from 'react'
import Features from '../components/app/Features'
import OffersGrid from '../components/app/OffersGrid'
import Header from '../components/common/Header'
import AppCTA from '../components/cta/AppCTA'
import ContactCTA from '../components/cta/ContactCTA'
import Layout from '../components/layout'
import Seo from '../components/seo'

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="EV charging Mobile App | Download Urban Fox iOS or Android"
          description="Innovative, retractable charging solutions like the UEone with a class leading mobile app on iOS and Android"
        />
        <Header filter="App" />
        <Features />
        <OffersGrid />
        {/* <Faqs filter="App" /> */}
        <ContactCTA />
        <AppCTA topPadded />
      </Layout>
    )
  }
}

export default RootIndex
